<script>
    import { auth } from '$lib/auth';
    import { season, entries } from '../stores/stores';

    import { doc, deleteDoc } from 'firebase/firestore';
    import { db } from '$lib/firebase';

    import Card, {Content, Actions} from '@smui/card';
    import Button from '@smui/button';
    import Badge from '@smui-extra/badge';
    export let entry = {
        id: "Entries Not Loaded"
    }
    let entryStatus = 'Not Paid';
    if(entry.isPaid){
        entryStatus = 'Paid';
    }

    const handleDelete = async (entryName) => {
        await deleteDoc(doc(db, `/users/${$auth.user.uid}/seasons/${$season}/accounts`, entryName));
        entries.reload();
    } 

</script>

<div class="flex-item">
    <Card>
        <div style="padding: 1rem; {!entry.isPaid?'border: 2px solid red;':''}">
            <Content>
                <h4 class="mdc-typography--headline6" style="margin: 0;">
                    {entry.id} <Badge aria-label="notification count">{entryStatus}</Badge>
                </h4>
                Points: {entry.pointBalance}                
            </Content>
            <Actions>
                {#if entryStatus === 'Not Paid'}
                    <Button variant="raised" on:click={()=>{handleDelete(entry.id)}}>Delete</Button>
                    <Button variant="raised" href="/payment">Payment Instructions</Button>
                {/if}
                <Button variant="raised" href="/entries/{entry.id}">Reports</Button>
            </Actions>
        </div>
    </Card>
</div>

<style>
    .flex-item {
      /*background-color: #f1f1f1;*/
      padding: 10px;
      padding-right: 40px;
      flex: 20%;
    }
    
    @media (max-width: 800px) {
      .flex-item {
        flex: 100%;
      }
    }
</style>