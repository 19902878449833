<script>
    import Card, {Content, Actions} from '@smui/card';
    import Button from '@smui/button';
    import Badge from '@smui-extra/badge';
    export let week = {
        id: "Weeks Not Loaded"
    }
    const weekday = ["Sun","Mon","Tues","Wed","Thur","Fri","Sat"]
    let dateRangeString = weekday[week.minGameDate?.toDate().getDay()];
    dateRangeString += ` `;
    dateRangeString += week.minGameDate?.toDate().toLocaleString("en-US", {dateStyle: "short", timeStyle: "short"});
    dateRangeString += ` - `;
    dateRangeString += weekday[week.maxGameDate?.toDate().getDay()]
    dateRangeString += ` `;
    dateRangeString += week.maxGameDate?.toDate().toLocaleString("en-US", {dateStyle: "short", timeStyle: "short"});
    let weekStatus = 'Not Started';
    if(week.isPostWeekProcessed){
        weekStatus = 'Completed';
    }
    if(week.minGameDate?.toDate() < new Date() && !week.isPostWeekProcessed){
        weekStatus = 'In Progress';
    }

</script>


<div class="flex-item">
    <Card>
        <div style="padding: 1rem; {week.active?'border: 2px solid green;':''}">
            <Content>
                <h4 class="mdc-typography--headline6" style="margin: 0;">
                    {week.id} <Badge aria-label="notification count">{weekStatus}</Badge>
                </h4>
                {dateRangeString}
            </Content>
            <Actions>
                {#if weekStatus == 'Not Started'}
                    <Button variant="raised" href="/weeks/{week.id}/picks">Make Pick</Button>
                {/if}
                <Button variant="raised" href="/weeks/{week.id}">Reports</Button>
            </Actions>
        </div>
    </Card>
</div>


<style>
    .flex-item {
      /*background-color: #f1f1f1;*/
      padding: 10px;
      padding-right: 40px;
      flex: 20%;
    }
    
    @media (max-width: 800px) {
      .flex-item {
        flex: 100%;
      }
    }
</style>