<script lang="ts">
    import { auth } from '$lib/auth';
    import { season, entries } from '../stores/stores';
    import { doc, setDoc, Timestamp } from 'firebase/firestore';
    import { db } from '$lib/firebase';
    import Button from '@smui/button';
    import { goto } from '$app/navigation';
    import Card, {Content, Actions} from '@smui/card';
    import type { SnackbarComponentDev } from '@smui/snackbar';
    import Snackbar, { Label, Actions as SBActions } from '@smui/snackbar';    
    import IconButton, {Icon} from '@smui/icon-button';    

    let entryName = '';

    let snackbarSuccess: SnackbarComponentDev;
    
    const handleClick = async () => {
        if(!entryName){
            snackbarSuccess && snackbarSuccess.open();
            return null;
        }
        await setDoc(doc(db, `/users/${$auth.user.uid}/seasons/${$season}/accounts`, entryName), {
                createdAt: Timestamp.fromDate(new Date()),
                updatedAt: Timestamp.fromDate(new Date()),
                deletedAt: null,
                isPaid: false,
                pointBalance: 30,
                weekEliminated: null,
                season: $season
            }
        );
        entries.reload();
        entryName = '';
        goto(`/`);
    }
</script>

<div class="card-container">
    <Card>
        <h2 class="mdc-typography--headline6" style="margin: 0;">
            Create Entry
        </h2>
        <Content>
            <p>Each entry is $20</p>
            <label for="name">
                Name:
            </label>
            <input type="text" required placeholder="entry name" bind:value={entryName}>
        </Content>
        <Actions>
            <Button variant="raised" on:click={()=>{handleClick()}}>Create New Entry</Button>        
        </Actions>
    </Card>
</div>
<Snackbar bind:this={snackbarSuccess}>
    <Label
      >NOT CREATED:  An Entry Name Is Required</Label
    >
    <SBActions>
      <IconButton class="material-icons" title="Dismiss">close</IconButton>
    </SBActions>
</Snackbar>    
<style>
    div {
        padding: 10px;
    }
</style>

